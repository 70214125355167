/* For use in src/lib/core/theming/_palette.scss */
$md-green-sho: (
        50 : #e3edf0,
        100 : #b9d3d9,
        200 : #8ab6c0,
        300 : #5b99a7,
        400 : #378394,
        500 : #146d81,
        600 : #126579,
        700 : #0e5a6e,
        800 : #0b5064,
        900 : #063e51,
        A100 : #85dbff,
        A200 : #52cbff,
        A400 : #1fbcff,
        A700 : #05b5ff,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);
