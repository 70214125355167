/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    /*custom-float-label*/
    .ctm-flt-lbl {
        @apply  absolute text-sm text-[#146D81] duration-300
        transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white
        px-2 peer-focus:px-2  peer-focus:text-[#146D81] peer-placeholder-shown:scale-100
        peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2
        peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 peer-focus:font-medium;
    }

    /*custom-float-label-error*/
    .ctm-flt-lbl-err {
        @apply absolute text-sm text-[#F54748] duration-300
        transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white
        px-2 peer-focus:px-2 peer-focus:text-[#F54748] peer-placeholder-shown:scale-100
        peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2
        peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1;
    }

    .ctm-flt-lbl-err-textarea {
      @apply absolute text-sm text-[#F54748] dark:text-[#F54748] duration-300
      transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white
      px-2 peer-focus:px-2 peer-focus:text-[#F54748] peer-placeholder-shown:scale-100
      peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-[24px]
      peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1;
    }

    .ctm-flt-lbl-textarea {
      @apply  absolute text-sm text-[#146D81] dark:text-[#146D81] duration-300
      transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white
      px-2 peer-focus:px-2  peer-focus:text-[#146D81] peer-placeholder-shown:scale-100
      peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-[24px]
      peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 peer-focus:font-medium;
  }

    /*custom-float-input*/
    .ctm-flt-inp {
        @apply block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900
        rounded-lg border border-gray-900 focus:outline-none focus:ring-0
        focus:border-[#122B36];
    }

    /*custom-float-input-error*/
    .ctm-flt-inp-err {
        @apply block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900
        rounded-lg border border-[#F54748] focus:outline-none focus:ring-0
        focus:border-[#F54748];
    }
}

@import '@angular/cdk/overlay-prebuilt.css';

body { margin: 0; font-family: Lato, sans-serif; }
